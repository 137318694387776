import React from 'react';
import {useSearchParamsString} from '../../hooks/useNavigateWithParams';
import {SparqlViewConfig} from '@logilab/sparqlexplorer-views';

export interface URIWithSelectedViewProps {
    endpoint: string;
    uri: string;
    viewWrapper: SparqlViewConfig;
}

export function URIWithSelectedView({
    endpoint,
    uri,
    viewWrapper,
}: URIWithSelectedViewProps) {
    const getSearchParamsString = useSearchParamsString();

    const Component = viewWrapper.component;
    return (
        <Component
            uri={uri}
            endpoint={endpoint}
            generateUrl={(originalUrl: string) => {
                const encodedUri = encodeURIComponent(originalUrl);
                return `#/browse/${encodedUri}?${getSearchParamsString()}`;
            }}
        />
    );
}
