import React from 'react';
import {Stack, Typography, Alert} from '@mui/material';
import {EndpointForm} from './EndpointForm';
import {useCanEditEndpoint, useEndpoint} from '../context/ConfigContext';
import {ClassList} from './ClassList';

export function HomePage() {
    const endpoint = useEndpoint();
    const canEditEndpoint = useCanEditEndpoint();
    console.log('home');
    return (
        <Stack spacing={4}>
            <Stack spacing={2}>
                <Typography variant="h4" component={'h1'}>
                    Welcome to SparqlExplorer
                </Typography>

                {canEditEndpoint ? (
                    <Stack spacing={1}>
                        <Typography>
                            Enter the URL of your SPARQL endpoint below to
                            browse its data conveniently using an hypertext user
                            interface:
                        </Typography>
                        <EndpointForm />
                    </Stack>
                ) : (
                    <Alert severity="info">
                        <Stack
                            direction={'row'}
                            spacing={1}
                            alignItems={'baseline'}
                        >
                            <Typography>Currently exploring</Typography>
                            <Typography fontFamily={'monospace'}>
                                {endpoint}
                            </Typography>
                        </Stack>
                    </Alert>
                )}
            </Stack>
            {endpoint ? (
                <Stack spacing={1}>
                    <Typography variant="h4">Available Classes</Typography>
                    <ClassList endpoint={endpoint} />
                </Stack>
            ) : null}
        </Stack>
    );
}
