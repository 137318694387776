import {
    Stack,
    Box,
    Container,
    Typography,
    Paper,
    InputBase,
    IconButton,
    CircularProgress,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TablePagination,
    TextField,
} from '@mui/material';
import TablePaginationActions, {
    TablePaginationActionsProps,
} from '@mui/material/TablePagination/TablePaginationActions';
import React, {useState, useEffect, PropsWithChildren} from 'react';
import {useNavigateWithParams} from '../../hooks/useNavigateWithParams';
import {useParam} from '../../hooks/useParams';
import {ITriples, useURIData} from '../../hooks/useURIData';
import {getBestLabel} from '../../utils/utils';
import {Error, Loading, URILink} from '../UtilsComponents';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import BlockIcon from '@mui/icons-material/Block';

function usePageParam() {
    const pageParam = useParam('page');
    const parsedPage = parseInt(pageParam ?? '0');
    const page = parsedPage > 0 && !isNaN(parsedPage) ? parsedPage - 1 : 0;
    return page;
}

function usePerPageParam() {
    const perPageParam = useParam('perpage');
    const parsedPerPage = parseInt(perPageParam ?? '20');
    const perPage =
        !isNaN(parsedPerPage) &&
        (parsedPerPage === 20 || parsedPerPage === 50 || parsedPerPage === 100)
            ? parsedPerPage
            : 20;
    return perPage;
}

function useFilter() {
    const filter = useParam('filter');
    return filter ?? '';
}

export function URIDefaultView({
    endpoint,
    uri,
    graphs,
    showUri = true,
}: {
    endpoint: string;
    uri: string;
    graphs: string[];
    showUri?: boolean;
}) {
    const pageNumber = usePageParam();
    const filter = useFilter();
    const perPage = usePerPageParam();
    const [filterInput, setFilterInput] = useState(filter);
    const {triples, total, labels, error, isLoaded} = useURIData({
        endpoint,
        uri,
        graphs,
        search: filter,
        page: pageNumber,
        perPage: perPage,
    });
    const navigate = useNavigateWithParams();

    function updatePage(newPage: number) {
        const params: Record<string, string> = {
            page: (newPage + 1).toString(),
            perpage: perPage.toString(),
        };
        if (filter !== '') {
            params['filter'] = filter;
        }
        navigate(`/browse/${encodeURIComponent(uri)}`, params);
    }

    function updatePerPage(newPerPage: string) {
        const params: Record<string, string> = {
            perpage: newPerPage,
        };
        if (filter !== '') {
            params['filter'] = filter;
        }
        navigate(`/browse/${encodeURIComponent(uri)}`, params);
    }

    function updateFilter(newFilter: string) {
        navigate(`/browse/${encodeURIComponent(uri)}`, {
            page: '1',
            perpage: perPage.toString(),
            filter: newFilter,
        });
    }

    useEffect(() => {
        if (filterInput !== filter) {
            setFilterInput(filter);
        }
    }, [filter]);

    const hasResults = triples.length !== 0;

    return (
        <Stack spacing={1}>
            {showUri ? (
                <Box>
                    <Container>
                        {isLoaded ? (
                            <Typography variant="h4">
                                {getBestLabel({labels, uri})}
                            </Typography>
                        ) : (
                            <CircularProgress />
                        )}
                    </Container>
                </Box>
            ) : null}
            <Box padding={2}>
                <Paper>
                    <Box padding={2}>
                        <Paper
                            component="form"
                            sx={{
                                paddingLeft: 2,
                                paddingRight: 2,
                                paddingTop: 1,
                                paddingBottom: 1,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            onSubmit={(e) => {
                                e.preventDefault();
                                // @ts-expect-error ts does not understand it works
                                const value = e.target.elements[0].value;
                                updateFilter(value);
                            }}
                        >
                            <InputBase
                                type="text"
                                placeholder="Filter triples"
                                name="search"
                                disabled={!hasResults && filter.length === 0}
                                sx={{
                                    flex: 1,
                                }}
                                value={filterInput}
                                onChange={(event) =>
                                    setFilterInput(event.target.value)
                                }
                            />
                            {isLoaded ? (
                                <IconButton type="submit" aria-label="search">
                                    <FilterAltIcon />
                                </IconButton>
                            ) : (
                                <CircularProgress />
                            )}
                        </Paper>
                    </Box>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography fontWeight={600}>
                                            Subject
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography fontWeight={600}>
                                            Predicate
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography fontWeight={600}>
                                            Object
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography fontWeight={600}>
                                            Graph
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableContent
                                    triples={triples}
                                    uri={uri}
                                    isLoaded={isLoaded}
                                    error={error}
                                    perPage={perPage}
                                />
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box paddingRight={1}>
                        <Stack
                            direction="row"
                            alignItems={'center'}
                            justifyContent={'end'}
                        >
                            <TablePagination
                                component={'div'}
                                rowsPerPageOptions={[20, 50, 100]}
                                page={pageNumber}
                                onPageChange={(_, page) => updatePage(page)}
                                onRowsPerPageChange={(event) => {
                                    updatePerPage(event.target.value);
                                }}
                                count={total}
                                rowsPerPage={perPage}
                                showFirstButton
                                showLastButton
                                ActionsComponent={CustomPaginationActionAction}
                            />
                            <Box width={30}>
                                {!isLoaded ? (
                                    <CircularProgress size={30} />
                                ) : null}
                            </Box>
                        </Stack>
                    </Box>
                </Paper>
            </Box>
        </Stack>
    );
}

function CustomPaginationActionAction(props: TablePaginationActionsProps) {
    const totalPages = Math.ceil(props.count / props.rowsPerPage);
    const currentPage = props.page + 1;
    const [inputValue, setInputValue] = useState(currentPage);

    useEffect(() => {
        setInputValue(currentPage);
    }, [currentPage]);

    return (
        <Box flexShrink={0} paddingLeft={2} paddingTop={1} paddingBottom={1}>
            <Stack direction={'row'} alignItems={'center'}>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <Box
                        component={'form'}
                        onSubmit={() => {
                            if (isNaN(inputValue) || inputValue <= 0) {
                                props.onPageChange(
                                    // @ts-expect-error we don't use this event
                                    undefined,
                                    0
                                );
                                // Make sure to reset the input value if we are already on the first page
                                // as the useEffect would not get triggered
                                setInputValue(1);
                            } else if (inputValue > totalPages) {
                                props.onPageChange(
                                    // @ts-expect-error we don't use this event
                                    undefined,
                                    totalPages - 1
                                );
                                // Make sure to reset the input value if we are already on the last page
                                // as the useEffect would not get triggered
                                setInputValue(totalPages);
                            } else {
                                props.onPageChange(
                                    // @ts-expect-error we don't use this event
                                    undefined,
                                    inputValue - 1
                                );
                            }
                        }}
                    >
                        <TextField
                            label={'Page'}
                            onChange={(event) => {
                                const intValue = parseInt(event.target.value);
                                if (isNaN(intValue)) {
                                    setInputValue(0);
                                } else {
                                    setInputValue(intValue);
                                }
                            }}
                            size="small"
                            sx={{
                                width: 60,
                            }}
                            value={inputValue}
                        />
                    </Box>
                    <Typography>/ {totalPages}</Typography>
                </Stack>
                <TablePaginationActions {...props} style={{marginLeft: 0}} />
            </Stack>
        </Box>
    );
}

function TableContent({
    triples,
    uri,
    error,
    isLoaded,
    perPage,
}: {
    triples: ITriples[];
    uri: string;
    error: string | null;
    isLoaded: boolean;
    perPage: number;
}) {
    const hasResults = triples.length !== 0;
    if (!isLoaded) {
        return (
            <FullSizeTableCell perPage={perPage}>
                <Loading />
            </FullSizeTableCell>
        );
    }
    if (error) {
        return (
            <FullSizeTableCell perPage={perPage}>
                <Error error={error} />
            </FullSizeTableCell>
        );
    }
    if (hasResults) {
        const emptyCells = perPage - triples.length;
        return (
            <>
                {triples.map((triple, i) => (
                    <TableRow key={i}>
                        <TableCell>
                            <URILink node={triple.subject} focusUri={uri} />
                        </TableCell>
                        <TableCell>
                            <URILink node={triple.predicate} focusUri={uri} />
                        </TableCell>
                        <TableCell>
                            <URILink node={triple.object} focusUri={uri} />
                        </TableCell>
                        <TableCell>
                            <URILink node={triple.graph} />
                        </TableCell>
                    </TableRow>
                ))}
                {emptyCells > 0 ? (
                    <TableRow sx={{height: 57 * emptyCells}}>
                        <TableCell colSpan={4}> </TableCell>
                    </TableRow>
                ) : null}
            </>
        );
    }
    return (
        <FullSizeTableCell perPage={perPage}>
            <Stack spacing={4} display={'flex'} justifyContent={'center'}>
                <Box display={'flex'} justifyContent={'center'}>
                    <BlockIcon color="disabled" sx={{fontSize: '15em'}} />
                </Box>
                <Typography
                    variant="subtitle1"
                    color={'GrayText'}
                    textAlign={'center'}
                >
                    No results
                </Typography>
            </Stack>
        </FullSizeTableCell>
    );
}

function FullSizeTableCell({
    children,
    perPage,
}: PropsWithChildren<{perPage: number}>) {
    return (
        <TableRow
            sx={{
                // 57 = size of one row
                height: 57 * perPage,
            }}
        >
            <TableCell colSpan={4}>{children}</TableCell>
        </TableRow>
    );
}
