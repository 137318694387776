import ChevronRight from '@mui/icons-material/ChevronRight';
import Inventory2 from '@mui/icons-material/Inventory2';
import LocationOn from '@mui/icons-material/LocationOn';
import Face from '@mui/icons-material/Face';
import Widgets from '@mui/icons-material/Widgets';
import LibraryBooks from '@mui/icons-material/LibraryBooks';
import TextFields from '@mui/icons-material/TextFields';
import ListAlt from '@mui/icons-material/ListAlt';

export function getIconFromURI(uri: string) {
    switch (uri) {
        case 'http://purl.org/dc/dcmitype/PhysicalObject':
            return Inventory2;
        case 'http://purl.org/dc/terms/Location':
            return LocationOn;
        case 'http://xmlns.com/foaf/0.1/Agent':
            return Face;
        case 'http://www.w3.org/2000/01/rdf-schema#Class':
        case 'http://www.w3.org/2002/07/owl#Class':
            return Widgets;
        case 'http://www.w3.org/2002/07/owl#Ontology':
            return LibraryBooks;
        case 'http://www.w3.org/2002/07/owl#AnnotationProperty':
        case 'http://www.w3.org/2002/07/owl#OntologyProperty':
        case 'http://www.w3.org/1999/02/22-rdf-syntax-ns#Property':
            return TextFields;
        case 'http://www.w3.org/2004/02/skos/core#Concept':
            return ListAlt;
        default:
            return ChevronRight;
    }
}
