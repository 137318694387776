import * as React from 'react';
import {useEndpoint} from '../context/ConfigContext';
import {useNavigateWithParams} from '../hooks/useNavigateWithParams';
import {querySparql} from '@logilab/sparqlutils';
import {addToSavedEndpoints, getSavedEndpoints} from '../utils/utils';
import {Autocomplete, Button, Stack, TextField} from '@mui/material';
import ExploreIcon from '@mui/icons-material/Explore';

async function checkServerValid(endpoint: string) {
    try {
        await querySparql(endpoint, `ASK { ?s ?p ?o }`);
        return true;
    } catch {
        return false;
    }
}

export const EndpointForm = () => {
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | undefined>();
    const savedEndpoints = getSavedEndpoints();

    const endpoint = useEndpoint();

    const [endpointInput, setEndpointInput] = React.useState<string>(
        endpoint ?? ''
    );

    const navigate = useNavigateWithParams();

    React.useEffect(() => {
        if (error) {
            setError(undefined);
        }
    }, [endpointInput]);

    return (
        <form
            onSubmit={async (e) => {
                e.preventDefault();
                setLoading(true);
                const valid = await checkServerValid(endpointInput);
                setLoading(false);
                if (!valid) {
                    setError('Invalid endpoint');
                    return;
                }
                addToSavedEndpoints(endpointInput);

                navigate('/', {
                    endpoint: endpointInput,
                    graphs: null,
                });
            }}
        >
            <Stack spacing={1} direction={'row'}>
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={savedEndpoints}
                    freeSolo
                    value={endpoint}
                    onChange={(_, value) => setEndpointInput(value ?? '')}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Sparql Endpoint"
                            onChange={(event) =>
                                setEndpointInput(event.target.value)
                            }
                            error={error !== undefined}
                            helperText={error}
                        />
                    )}
                    fullWidth
                />
                <Button
                    variant="contained"
                    type="submit"
                    disabled={endpointInput === '' || loading}
                    startIcon={<ExploreIcon />}
                >
                    Explore
                </Button>
            </Stack>
        </form>
    );
};
