import React from 'react';
import packageJson from '../../../package.json';

export function Footer() {
    return (
        <footer
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderTop: 1,
                borderTopStyle: 'solid',
                borderTopColor: 'rgba(140, 130, 115, 0.1)',
                padding: 5,
            }}
        >
            <p style={{margin: 0}}>
                SparqlExplorer v{packageJson.version} made with ✨ by{' '}
                <a
                    href="https://logilab.fr/"
                    style={{
                        textDecoration: 'none',
                    }}
                    target="_blank"
                >
                    Logilab
                </a>
                .
            </p>
        </footer>
    );
}
