import React, {PropsWithChildren, useContext} from 'react';

export type SparqlExplorerConfig = {
    endpoint: string | null;
    canEditEndpoint: boolean;
    view: string | null;
    selectedGraphs: string[];
};

const ConfigContext = React.createContext<SparqlExplorerConfig>({
    endpoint: null,
    canEditEndpoint: true,
    view: null,
    selectedGraphs: [],
});

export function ConfigProvider({
    children,
    config,
}: PropsWithChildren<{config: SparqlExplorerConfig}>) {
    return (
        <ConfigContext.Provider value={config}>
            {children}
        </ConfigContext.Provider>
    );
}

export function useConfig() {
    return useContext(ConfigContext);
}

export function useEndpoint() {
    const {endpoint} = useConfig();
    return endpoint;
}

export function useView() {
    const {view} = useConfig();
    return view;
}

export function useCanEditEndpoint() {
    const {canEditEndpoint} = useConfig();
    return canEditEndpoint;
}

export function useSelectedGraphs() {
    const {selectedGraphs} = useConfig();
    return selectedGraphs.length === 0 ? ['default'] : selectedGraphs;
}
