import React from 'react';
import {Loading, Error} from './UtilsComponents';
import {useClasses} from '../hooks/useClasses';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Card,
    CardActionArea,
    CardContent,
    Chip,
    Grid,
    InputAdornment,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import {useURILink} from '../hooks/useURILink';
import {Link} from 'react-router-dom';
import {prefixedNameFromUri} from '../utils/utils';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {getIconFromURI} from '../utils/getIconFromURI';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export interface ListQueryResult {
    uri: string;
    nbInstances?: string;
    label?: string;
}

export interface ClassListProps {
    endpoint: string;
}

function isMetaClass(uri: string) {
    return (
        uri.startsWith('http://www.w3.org/1999/02/22-rdf-syntax-ns') ||
        uri.startsWith('http://www.w3.org/2000/01/rdf-schema') ||
        uri.startsWith('http://www.w3.org/ns/sparql-service-description') ||
        uri.startsWith('http://www.w3.org/2002/07/owl')
    );
}

export function ClassList({endpoint}: ClassListProps) {
    const {classes, isLoaded, error} = useClasses({endpoint});
    console.log('render');
    const [fitlerClassLabel, setFilterClassLabel] = React.useState<string>('');
    if (!isLoaded) {
        return <Loading />;
    } else if (error !== null) {
        return <Error error={error} />;
    }

    const fitleredClasses = classes.filter((item) =>
        item.uri.toLowerCase().includes(fitlerClassLabel.toLowerCase())
    );

    const metaClasses: ListQueryResult[] = [];
    const mainClasses: ListQueryResult[] = [];
    fitleredClasses.forEach((c) => {
        if (isMetaClass(c.uri)) {
            metaClasses.push(c);
        } else {
            mainClasses.push(c);
        }
    });

    return (
        <Stack spacing={1}>
            <TextField
                value={fitlerClassLabel}
                onChange={(e) => setFilterClassLabel(e.target.value)}
                type="text"
                placeholder="Filter classes"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">
                            <FilterAltIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <Box>
                <Grid container spacing={2} columns={12}>
                    {mainClasses.map((item) => (
                        <ClassListItem
                            key={item.uri}
                            uri={item.uri}
                            instanceCount={item.nbInstances}
                            label={item.label}
                        />
                    ))}
                </Grid>
            </Box>
            {metaClasses.length > 0 ? (
                <Accordion>
                    <AccordionSummary expandIcon={<ArrowDownIcon />}>
                        <Typography>Meta Classes</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2} columns={12}>
                            {metaClasses.map((item) => (
                                <ClassListItem
                                    key={item.uri}
                                    uri={item.uri}
                                    instanceCount={item.nbInstances}
                                    label={item.label}
                                />
                            ))}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            ) : null}
        </Stack>
    );
}

function ClassListItem({
    uri,
    instanceCount,
    label,
}: {
    uri: string;
    instanceCount?: string;
    label?: string;
}) {
    const uriLink = useURILink(uri);

    const Icon = getIconFromURI(uri);

    return (
        <Grid item xs={4}>
            <Link
                key={uri}
                to={uriLink}
                style={{
                    textDecoration: 'none',
                    color: 'inherit',
                }}
            >
                <Card sx={{minHeight: 80, display: 'flex'}}>
                    <CardActionArea sx={{display: 'flex', flex: 1}}>
                        <CardContent sx={{display: 'flex', flex: 1}}>
                            <Stack
                                flex={1}
                                spacing={2}
                                direction={'row'}
                                alignItems={'center'}
                            >
                                <Icon sx={{color: 'GrayText'}} />
                                <Stack flex={1}>
                                    <Typography variant="body1">
                                        {label ?? prefixedNameFromUri(uri)}
                                    </Typography>
                                    {label ? (
                                        <Typography
                                            variant="caption"
                                            color={'GrayText'}
                                        >
                                            {prefixedNameFromUri(uri)}
                                        </Typography>
                                    ) : null}
                                </Stack>
                                {instanceCount !== undefined ? (
                                    <Chip size="small" label={instanceCount} />
                                ) : null}
                            </Stack>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Link>
        </Grid>
    );
}
