import {SparqlViewConfig} from '@logilab/sparqlexplorer-views';
import React, {PropsWithChildren, useContext} from 'react';

const ApplicableViewsContext = React.createContext<{
    applicableViews?: SparqlViewConfig[];
    setApplicableViews: (views?: SparqlViewConfig[]) => void;
}>({applicableViews: [], setApplicableViews: () => undefined});

export function ApplicableViewsProvider({children}: PropsWithChildren) {
    const [applicableViews, setApplicableViews] = React.useState<
        SparqlViewConfig[] | undefined
    >();
    return (
        <ApplicableViewsContext.Provider
            value={{
                applicableViews,
                setApplicableViews,
            }}
        >
            {children}
        </ApplicableViewsContext.Provider>
    );
}

export function useApplicableViews() {
    return useContext(ApplicableViewsContext);
}
