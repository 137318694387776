// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-group-item.view-item {
    border-color: white;
    width: 80%;
    font-size: 1rem;
}
.list-group-item.view-item:not(.list-group-item-success) {
    background: none;
    color: black;
}
.list-group-item.view-item.disabled:not(.list-group-item-success) {
    background: none;
    color: grey;
}
.list-group-item.view-item:hover {
    background-color: lightblue;
    color: black;
}

.view-list {
    max-height: 400px;
    overflow-y: auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/ViewSelector.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,UAAU;IACV,eAAe;AACnB;AACA;IACI,gBAAgB;IAChB,YAAY;AAChB;AACA;IACI,gBAAgB;IAChB,WAAW;AACf;AACA;IACI,2BAA2B;IAC3B,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":[".list-group-item.view-item {\n    border-color: white;\n    width: 80%;\n    font-size: 1rem;\n}\n.list-group-item.view-item:not(.list-group-item-success) {\n    background: none;\n    color: black;\n}\n.list-group-item.view-item.disabled:not(.list-group-item-success) {\n    background: none;\n    color: grey;\n}\n.list-group-item.view-item:hover {\n    background-color: lightblue;\n    color: black;\n}\n\n.view-list {\n    max-height: 400px;\n    overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
