import React, {useState} from 'react';

import {useNavigateWithParams} from '../hooks/useNavigateWithParams';
import {useParams} from 'react-router-dom';
import {useSelectedView} from '../context/SelectedViewContext';
import {getApplicableViews, isDefaultView} from '../utils/utils';
import {useApplicableViews} from '../context/ApplicableViewsContext';
import {
    useEndpoint,
    useSelectedGraphs,
    useView,
} from '../context/ConfigContext';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Snackbar,
    Stack,
    Typography,
} from '@mui/material';
import {Loading} from './UtilsComponents';
import {URIDefaultView} from './uri/URIDefaultView';
import {URIWithSelectedView} from './uri/URIWithSelectedView';
import {SparqlViewConfig} from '@logilab/sparqlexplorer-views';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export function UriPage() {
    const params = useParams();
    const endpoint = useEndpoint();
    const viewName = useView();
    const selectedGraphs = useSelectedGraphs();
    const uri = params['*'];

    const [loadingViews, setLoadingViews] = useState(viewName !== null);
    const {selectedView, setSelectedView} = useSelectedView();
    const {setApplicableViews} = useApplicableViews();
    const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);

    const navigate = useNavigateWithParams();

    React.useEffect(() => {
        setSnackbarMessage(null);
        if (uri === undefined || endpoint === null) {
            return;
        }
        setLoadingViews(isDefaultView(viewName));
        getApplicableViews(uri, endpoint).then((applicableViews) => {
            setApplicableViews(applicableViews);
            if (viewName === null) {
                if (applicableViews.length > 0) {
                    setSelectedView(applicableViews[0]);
                } else {
                    setSnackbarMessage(
                        'No view suitable for these data, falling back to default'
                    );
                    setSelectedView(undefined);
                }
            } else if (!isDefaultView(viewName)) {
                const validView = applicableViews.find(
                    (v) => v.name === viewName
                );
                if (validView) {
                    setSelectedView(validView);
                } else {
                    setSnackbarMessage(
                        'No view suitable for these data, falling back to default'
                    );
                    setSelectedView(undefined);
                }
            } else {
                setSelectedView(undefined);
            }
            setLoadingViews(false);
        });
    }, [viewName, endpoint, uri, navigate]);

    if (endpoint === null || uri === undefined) {
        navigate('/', undefined, true);
        return null;
    }

    if (loadingViews) {
        return <Loading />;
    }

    let content = null;
    if (selectedView === undefined) {
        content = (
            <URIDefaultView
                uri={uri}
                endpoint={endpoint ?? ''}
                graphs={selectedGraphs}
            />
        );
    } else {
        content = (
            <CustomView
                uri={uri}
                endpoint={endpoint ?? ''}
                graphs={selectedGraphs}
                selectedView={selectedView}
            />
        );
    }

    return (
        <>
            <Snackbar
                open={snackbarMessage !== null}
                autoHideDuration={6000}
                onClose={() => setSnackbarMessage(null)}
                message={snackbarMessage}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            />
            {content}
        </>
    );
}

function CustomView({
    endpoint,
    uri,
    graphs,
    selectedView,
}: {
    endpoint: string;
    uri: string;
    graphs: string[];
    selectedView: SparqlViewConfig;
}) {
    return (
        <Stack spacing={5}>
            <Box>
                <URIWithSelectedView
                    uri={uri}
                    endpoint={endpoint}
                    viewWrapper={selectedView}
                />
            </Box>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ArrowDownIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography>See all triples</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <URIDefaultView
                        uri={uri}
                        endpoint={endpoint}
                        graphs={graphs}
                        showUri={false}
                    />
                </AccordionDetails>
            </Accordion>
        </Stack>
    );
}
