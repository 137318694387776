import React, {PropsWithChildren, useState} from 'react';

import {NavBar} from './Navbar';
import {Footer} from './Footer';
import {ConfigProvider} from '../../context/ConfigContext';
import {useParam} from '../../hooks/useParams';
import {Box, Drawer, Stack, styled} from '@mui/material';
import {DRAWER_WIDTH, DrawerContent} from './DrawerContent';

const Main = styled('div', {shouldForwardProp: (prop) => prop !== 'open'})<{
    open?: boolean;
}>(({theme, open}) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    ...(open && {
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        marginLeft: `${DRAWER_WIDTH}px`,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const OPEN_DRAWER_KEY = 'sparqlexplorer_drawer open';

function shouldOpenDrawer() {
    return window.localStorage.getItem(OPEN_DRAWER_KEY) === '1';
}

function saveDrawerState(open: boolean) {
    return window.localStorage.setItem(OPEN_DRAWER_KEY, open ? '1' : '0');
}

function useGraphsParam(): string[] {
    const selectedGraphsParam = useParam('graphs');
    const parsedGraphs = JSON.parse(selectedGraphsParam ?? '[]');
    if (
        Array.isArray(parsedGraphs) &&
        parsedGraphs.every((v) => typeof v === 'string')
    ) {
        return parsedGraphs;
    }
    return [];
}

export function Layout({children}: PropsWithChildren) {
    const endpointParam = useParam('endpoint');
    const endpointFixed = window.SPARQL_ENDPOINT;
    const isEndpointFixed = endpointFixed !== null && endpointFixed !== '';
    const endpoint = isEndpointFixed ? endpointFixed : endpointParam;
    const view = useParam('view');
    const selectedGraphs = useGraphsParam();
    const [drawerOpen, setDrawerOpen] = useState(shouldOpenDrawer());

    function setDrawerState(open: boolean) {
        saveDrawerState(open);
        setDrawerOpen(open);
    }

    return (
        <ConfigProvider
            config={{
                endpoint,
                canEditEndpoint: !isEndpointFixed,
                view,
                selectedGraphs,
            }}
        >
            <div
                style={{
                    minHeight: '100vh',
                }}
            >
                <Stack spacing={2} sx={{minHeight: '100vh'}}>
                    <Box>
                        <NavBar
                            onBurgerPressed={() => setDrawerState(!drawerOpen)}
                            drawerOpen={drawerOpen}
                        />
                    </Box>
                    <Box display={'flex'} flex={1}>
                        <Main open={drawerOpen}>{children}</Main>
                    </Box>
                    <Footer />
                </Stack>
                <Drawer
                    open={drawerOpen}
                    onClose={() => setDrawerState(false)}
                    variant="persistent"
                >
                    <DrawerContent onClose={() => setDrawerState(false)} />
                </Drawer>
            </div>
        </ConfigProvider>
    );
}
