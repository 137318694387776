import {useEffect, useState} from 'react';
import {sparqlResultToArray, querySparql} from '@logilab/sparqlutils';
import {Label, RDFNode, getLabelSparqlClause} from '../utils/utils';

export interface ITriples {
    // use objects from rdfjs instead ?
    subject: RDFNode;
    predicate: RDFNode;
    object: RDFNode;
    graph: RDFNode;
}

async function fetchUriData(
    endpoint: string,
    uri: string,
    graphs: string[],
    search: string,
    page: number,
    perPage: number
): Promise<{triples: ITriples[]; total: number; labels: Label[]}> {
    const triples: ITriples[] = [];
    const escapedSearch = search.replace('"', '\\"');
    const searchFilter =
        search !== ''
            ? ` FILTER (
            regex(str(?s), "${escapedSearch}", "i") OR
            regex(str(?p), "${escapedSearch}", "i") OR
            regex(str(?o), "${escapedSearch}", "i") OR
            regex(str(?g), "${escapedSearch}", "i")
        )`
            : '';

    const defaultGraphSelect = `
        {
            {
                <${uri}> ?p ?o.
                BIND (<${uri}> AS ?s)
            } UNION {
                ?s ?p <${uri}>
                BIND (<${uri}> AS ?o)
            }
        }`;

    function getSpecificGraphSelect(g: string) {
        return `
        {
            {
                GRAPH <${g}> {
                    <${uri}> ?p ?o.
                }
                BIND (<${uri}> AS ?s)
            } UNION {
                GRAPH <${g}> {
                    ?s ?p <${uri}>
                }
                BIND (<${uri}> AS ?o)
            }
            BIND (<${g}> AS ?g)
        }`;
    }
    const graphList = graphs
        .filter((g) => g !== 'default')
        .map((g) => getSpecificGraphSelect(g))
        .join(' UNION ');

    let graphSelect = '';
    if (graphs.includes('default')) {
        graphSelect += defaultGraphSelect;
        if (graphs.length > 1) {
            graphSelect += ` UNION ${graphList}`;
        }
    } else {
        graphSelect = graphList;
    }

    const fetchTriplesWhereQuery = `${graphSelect}${searchFilter}`;
    const [triplesResult, countResult, labelResult] = await Promise.all([
        querySparql(
            endpoint,
            `SELECT DISTINCT ?g ?s ?p ?o WHERE {${fetchTriplesWhereQuery} } ORDER BY ?p LIMIT ${perPage} OFFSET ${page * perPage}`
        ),
        querySparql(
            endpoint,
            `SELECT (COUNT(*) as ?count) WHERE { ${fetchTriplesWhereQuery} }`
        ),
        querySparql(
            endpoint,
            `SELECT DISTINCT ?label WHERE {
                ${getLabelSparqlClause(uri)}
            }`
        ),
    ]);

    sparqlResultToArray(triplesResult).forEach((elem) => {
        triples.push({
            subject: elem['s'],
            predicate: elem['p'],
            object: elem['o'],
            graph: elem['g'] ?? {type: 'uri', value: 'default'},
        });
    });

    const total = parseInt(countResult.results.bindings[0].count.value);

    const labels: Label[] = [];

    sparqlResultToArray(labelResult).forEach((elem) => {
        labels.push({
            value: elem['label']?.value,
            lang: null,
        });
    });

    return {
        triples,
        total,
        labels,
    };
}

export function useURIData({
    endpoint,
    uri,
    graphs,
    search,
    page,
    perPage,
}: {
    endpoint: string;
    uri: string;
    graphs: string[];
    search: string;
    page: number;
    perPage: number;
}) {
    const [triples, setTriples] = useState<ITriples[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [total, setTotal] = useState(0);
    const [labels, setLabels] = useState<Label[]>([]);
    useEffect(() => {
        async function fetchResults() {
            try {
                setIsLoaded(false);
                const {
                    triples: tempTriples,
                    total: tempTotals,
                    labels: tempLabels,
                } = await fetchUriData(
                    endpoint,
                    uri,
                    graphs,
                    search,
                    page,
                    perPage
                );
                setTriples(tempTriples);
                setTotal(tempTotals);
                setLabels(tempLabels);
                setIsLoaded(true);
            } catch (e) {
                setError(e.message);
            }
        }
        fetchResults();
    }, [endpoint, uri, graphs, search, page, perPage]);

    return {triples, total, labels, error, isLoaded};
}
