import React from 'react';

import {Layout} from '../components/layout/Layout';
import {UriPage} from '../components/UriPage';

export function Uri() {
    return (
        <Layout>
            <UriPage />
        </Layout>
    );
}
