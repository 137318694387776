import React from 'react';
import {Link} from 'react-router-dom';

import {RDFNode, prefixedNameFromUri} from '../utils/utils';
import {useURILink} from '../hooks/useURILink';
import {Alert, Box, CircularProgress, Typography} from '@mui/material';

export function Loading() {
    return (
        <Box
            height={'100%'}
            minHeight={200}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
        >
            <CircularProgress />
        </Box>
    );
}

export function Error({error}: {error: string}) {
    return <Alert severity="error">{error}</Alert>;
}

export function NoResults() {
    return <div>No data to display</div>;
}

export function URILink({
    node,
    focusUri,
    label,
}: {
    node: RDFNode;
    focusUri?: string;
    label?: string;
}) {
    const uriLink = useURILink(node.value);
    if (node.type === 'uri') {
        const text = label ?? prefixedNameFromUri(node.value);
        if (node.value === focusUri) {
            return <Typography color={'GrayText'}>{text}</Typography>;
        } else {
            return (
                <Link to={uriLink}>
                    <Typography>{text}</Typography>
                </Link>
            );
        }
    }
    return <Typography color={'GrayText'}>{node.value}</Typography>;
}
