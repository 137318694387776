import {
    Alert,
    Box,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    List,
    ListItemButton,
    ListItemText,
    Switch,
    Typography,
} from '@mui/material';
import React, {useState} from 'react';
import {useGraphs} from '../hooks/useGraphs';
import {useEndpoint, useSelectedGraphs} from '../context/ConfigContext';
import {useAddGraph, useRemoveGraph} from '../hooks/useNavigateWithParams';

const SHOW_ALL_KEY = 'sparqlexplorer.graphs.showall';

function shouldShowAll() {
    return window.localStorage.getItem(SHOW_ALL_KEY) === '1';
}

function saveShowAll(open: boolean) {
    return window.localStorage.setItem(SHOW_ALL_KEY, open ? '1' : '0');
}

export function GraphSelector() {
    const endpoint = useEndpoint();
    const [showAll, setShowAll] = useState(shouldShowAll());
    const {error, graphs, isLoaded} = useGraphs(showAll);

    if (!endpoint) {
        return null;
    }

    function updateShowAll(newValue: boolean) {
        setShowAll(newValue);
        saveShowAll(newValue);
    }

    return (
        <Box>
            <Box paddingLeft={1} paddingRight={1}>
                <Typography variant="h6">Available Graphs</Typography>
            </Box>
            {isLoaded ? (
                <>
                    <Box paddingLeft={1} paddingRight={1}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={showAll}
                                    onChange={() => updateShowAll(!showAll)}
                                />
                            }
                            label="Show all"
                        />
                    </Box>
                    <GraphList graphs={graphs} error={error} />
                </>
            ) : (
                <Box paddingTop={5} display={'flex'} justifyContent={'center'}>
                    <CircularProgress />
                </Box>
            )}
        </Box>
    );
}

function GraphList({
    graphs,
    error,
}: {
    graphs: Array<{graph: string}>;
    error: string | null;
}) {
    if (error) {
        return <Alert severity="error">{error}</Alert>;
    }
    if (graphs.length === 0) {
        return <Typography color={'GrayText'}>No graphs found</Typography>;
    }

    const selectedGraphs = useSelectedGraphs();
    const addGraph = useAddGraph();
    const removeGraph = useRemoveGraph();

    return (
        <List>
            {graphs.map((g) => {
                const selected = selectedGraphs.includes(g.graph);
                return (
                    <ListItemButton
                        key={g.graph}
                        onClick={() => {
                            if (selected) {
                                removeGraph(g.graph);
                            } else {
                                addGraph(g.graph);
                            }
                        }}
                        disabled={
                            selected &&
                            g.graph === 'default' &&
                            selectedGraphs.length === 1
                        }
                    >
                        <Checkbox checked={selected} />
                        <ListItemText primary={g.graph} />
                    </ListItemButton>
                );
            })}
        </List>
    );
}
