import React from 'react';
import {HashRouter as Router, Routes, Route} from 'react-router-dom';

import './App.css';

import {Home} from './routes/Home';
import {Yasgui} from './routes/Yasgui';
import {Uri} from './routes/Uri';
import {SelectedViewProvider} from './context/SelectedViewContext';
import {ApplicableViewsProvider} from './context/ApplicableViewsContext';
import {Search} from './routes/Search';

function App() {
    return (
        <SelectedViewProvider>
            <ApplicableViewsProvider>
                <Router>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/yasgui" element={<Yasgui />} />
                        <Route path="/search" element={<Search />} />
                        <Route path="/browse/*" element={<Uri />} />
                        <Route path="*" element={<Home />} />
                    </Routes>
                </Router>
            </ApplicableViewsProvider>
        </SelectedViewProvider>
    );
}

export default App;
