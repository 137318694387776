import {useEffect, useState} from 'react';
import {useEndpoint} from '../context/ConfigContext';
import {querySparql, sparqlResultToArray} from '@logilab/sparqlutils';

export interface ListQueryResult {
    graph: string;
}

const IGNORED_GRAPHS = [
    'http://www.openlinksw.com/schemas/virtrdf#',
    'urn:core:services:sparql',
    'urn:activitystreams-owl:map',
];

export function useGraphs(showAll = false) {
    const endpoint = useEndpoint();
    const [graphs, setGraphs] = useState<ListQueryResult[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        async function fetchClasses() {
            if (!endpoint) {
                setGraphs([]);
                setIsLoaded(true);
                setError(null);
                return;
            }
            try {
                setIsLoaded(false);
                setError(null);
                const queryResults = await querySparql(
                    endpoint,
                    `SELECT DISTINCT ?g WHERE {
                        GRAPH ?g { ?s ?p ?o }
                    } `
                );
                const temp = sparqlResultToArray(queryResults).map((elem) => {
                    return {graph: elem['g'].value};
                });
                setGraphs([{graph: 'default'}, ...temp]);
            } catch (e) {
                setError(e.message);
            } finally {
                setIsLoaded(true);
            }
        }
        fetchClasses();
    }, [endpoint]);

    const ignoredGraphs = IGNORED_GRAPHS.map((g) => `<${g}>`).join(', ');
    return {
        graphs: showAll
            ? graphs
            : // Filtering within the request is too slow
              graphs.filter(
                  (e) =>
                      !ignoredGraphs.includes(e.graph) &&
                      !e.graph.startsWith('http://www.w3.org') &&
                      !e.graph.includes('/DAV')
              ),
        error,
        isLoaded,
    };
}
