import prefix from './prefixInverted.json';
import {SPARQL_VIEWS, SparqlViewConfig} from '@logilab/sparqlexplorer-views';

export interface RDFNode {
    type: 'uri' | 'bnode' | 'literal';
    value: string;
}

export function stringIncludes(a: string, b: string) {
    return a.toLowerCase().includes(b.toLowerCase());
}

export function computeRedirect(uri: string, params: string) {
    return `/#/browse/${encodeURIComponent(uri)}?${params}`;
}

export function prefixedNameFromUri(uri: string) {
    const prefixCandidates = Object.entries(prefix).filter(([baseUri]) =>
        uri.startsWith(baseUri)
    );
    if (prefixCandidates.length > 0) {
        const [bestBaseUri, bestPrefix] = prefixCandidates.sort(
            ([baseUri1], [baseUri2]) => baseUri1.length - baseUri2.length
        )[0];
        return uri.replace(bestBaseUri, `${bestPrefix}:`);
    }
    return uri;
}

const SAVED_ENDPOINT_KEY = 'sparqlexplorer.endpoints';

function stringToStringArray(rawValue: string | null): Array<string> {
    if (rawValue !== null) {
        try {
            const arrayValue = JSON.parse(rawValue);
            if (
                Array.isArray(arrayValue) &&
                arrayValue.every((v) => typeof v === 'string')
            ) {
                return arrayValue;
            }
            return [];
        } catch {
            return [];
        }
    }
    return [];
}

export function getSavedEndpoints(): Array<string> {
    const rawValue = localStorage.getItem(SAVED_ENDPOINT_KEY);
    return stringToStringArray(rawValue);
}

export function addToSavedEndpoints(value: string) {
    const current = new Set(getSavedEndpoints());
    current.add(value);
    const stringValue = JSON.stringify(Array.from(current));
    localStorage.setItem(SAVED_ENDPOINT_KEY, stringValue);
}

export function getApplicableViews(
    uri: string,
    endpoint: string
): Promise<Array<SparqlViewConfig>> {
    return Promise.all(
        SPARQL_VIEWS.map((view) => view.isApplicable(uri, endpoint))
    ).then((viewsApplicablities) => {
        return SPARQL_VIEWS.filter((v, i) => viewsApplicablities[i]);
    });
}

export const DEFAUT_VIEW_NAME = 'Default view';

export function isDefaultView(viewName: string | null) {
    return viewName === DEFAUT_VIEW_NAME;
}

export function getLabelSparqlClause(
    uri?: string,
    onlyRdfsLabel: boolean = false
) {
    const predicates = onlyRdfsLabel
        ? ['<http://www.w3.org/2000/01/rdf-schema#label>']
        : [
              '<http://www.w3.org/2000/01/rdf-schema#label>',
              '<http://xmlns.com/foaf/0.1/name>',
              '<http://purl.org/dc/terms/identifier>',
              '<http://www.w3.org/2004/02/skos/core#prefLabel>',
              '<http://www.w3.org/2008/05/skos-xl#prefLabel>/<http://www.w3.org/2008/05/skos-xl#literalForm>',
              '<http://purl.org/dc/elements/1.1/title>',
              '<http://purl.org/dc/terms/title>',
          ];
    const predicateString = predicates.join('|');
    const uriSelector = uri ? `<${uri}>` : '?uri';
    return `OPTIONAL{
        {
            ${uriSelector} ${predicateString} ?label
        }
    }`;
}

export interface Label {
    value: string;
    lang: string | null;
}

export interface UriAndLabels {
    uri: string;
    labels: Label[];
}

export function getBestLabel(instance: UriAndLabels) {
    const filteredLabels = instance.labels
        .filter((label) => label.lang?.includes('fr'))
        .map((label) => label['value']);
    const allLabels = instance.labels.map((label) => label['value']);

    return (
        filteredLabels[0] ?? allLabels[0] ?? prefixedNameFromUri(instance.uri)
    );
}
