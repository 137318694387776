import React from 'react';
import {SearchPage} from '../components/SearchPage';
import {Layout} from '../components/layout/Layout';
import {Container} from '@mui/material';

export function Search() {
    return (
        <Layout>
            <Container>
                <SearchPage />
            </Container>
        </Layout>
    );
}
