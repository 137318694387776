import {createSearchParams, useLocation, useNavigate} from 'react-router-dom';
import {useCallback} from 'react';
import {
    useCanEditEndpoint,
    useEndpoint,
    useSelectedGraphs,
    useView,
} from '../context/ConfigContext';

export function useNavigateWithParams() {
    const navigate = useNavigate();
    const getSearchParamsString = useSearchParamsString();

    return useCallback(
        (
            pathname: string,
            newParams?: Record<string, string | null>,
            replace?: boolean
        ) => {
            console.log(getSearchParamsString());
            navigate(
                {
                    pathname: pathname,
                    search: getSearchParamsString(newParams),
                },
                {replace}
            );
        },
        [navigate, getSearchParamsString]
    );
}

export function useSearchParamsString() {
    const endpoint = useEndpoint();
    const viewUrl = useView();
    const selectedGraphs = useSelectedGraphs();
    const canEditEndpoint = useCanEditEndpoint();

    return useCallback(
        (newParams?: Record<string, string | null>) => {
            const params: Record<string, string | null> = newParams ?? {};
            if (canEditEndpoint && endpoint && !('endpoint' in params)) {
                params['endpoint'] = endpoint;
            }
            if (viewUrl && !('view' in params)) {
                params['view'] = viewUrl;
            }
            if (!('graphs' in params)) {
                params['graphs'] = JSON.stringify(selectedGraphs);
            }

            const cleanedParams: Record<string, string> = {};
            Object.entries(params).forEach(([k, v]) => {
                if (v) {
                    cleanedParams[k] = v;
                }
            });
            return createSearchParams(cleanedParams).toString();
        },
        [endpoint, viewUrl, canEditEndpoint, selectedGraphs]
    );
}

export function useChangeView() {
    const navigate = useNavigateWithParams();
    const location = useLocation();
    return (view: string | null) => navigate(location.pathname, {view}, true);
}

export function useAddGraph() {
    const navigate = useNavigateWithParams();
    const location = useLocation();
    const selectedGraphs = useSelectedGraphs();
    return (graph: string) => {
        const newGraphs = [...selectedGraphs];
        newGraphs.push(graph);
        navigate(location.pathname, {graphs: JSON.stringify(newGraphs)}, true);
    };
}

export function useRemoveGraph() {
    const navigate = useNavigateWithParams();
    const location = useLocation();
    const selectedGraphs = useSelectedGraphs();
    return (graph: string) => {
        const newGraphs = selectedGraphs.filter((g) => g !== graph);
        navigate(location.pathname, {graphs: JSON.stringify(newGraphs)}, true);
    };
}
