import React, {useRef} from 'react';

import Yasgui from '@logilab/yasgui';
import {Yasqe as YasqeType} from '@logilab/yasqe';
import '@logilab/yasgui/build/yasgui.min.css';

import {YasrTableResults} from './YasrTableResults';
import {
    useNavigateWithParams,
    useSearchParamsString,
} from '../hooks/useNavigateWithParams';
import {useEndpoint} from '../context/ConfigContext';

const Yasqe = Yasgui.Yasqe;
const Yasr = Yasgui.Yasr;

Yasr.registerPlugin(
    'table',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    YasrTableResults as any
);

// @ts-expect-error FIXME why is typing broken here?
Yasqe.defaults.createShortLink = (yasque: YasqeType, longLink: string) => {
    return fetch('https://shlink.demo-dedibox.logilab.fr/rest/v3/short-urls', {
        method: 'POST',
        body: JSON.stringify({
            longUrl: longLink,
        }),
        headers: {
            'Content-type': 'application/json',
            Accept: 'application/json',
            'X-Api-Key': '3f3a637f-5eed-49d2-a440-7b5f024e9104',
        },
    })
        .then((response) => {
            return response
                .json()
                .then((data) => {
                    return data.shortUrl;
                })
                .catch((err) => {
                    console.log(err);
                    return longLink;
                });
        })
        .catch((err) => {
            console.log(err);
            return longLink;
        });
};

const createShareableLink = (yasqe: YasqeType): string => {
    const urlSplit = location.hash.split('?');
    const hashString = urlSplit[0];
    const searchString = urlSplit[1];
    const searchParams = new URLSearchParams(searchString);
    const currentQuery = yasqe.configToQueryParams().query as string;
    searchParams.set('yasguiQuery', currentQuery);
    const ret =
        document.location.protocol +
        '//' +
        document.location.host +
        document.location.pathname +
        hashString +
        '?' +
        searchParams.toString();
    return ret;
};

const consumeShareLink = (yasqe: YasqeType) => {
    const searchString = location.hash.split('?')[1];
    const searchParams = new URLSearchParams(searchString);
    const yasguiQuery = searchParams.get('yasguiQuery');
    if (yasguiQuery !== null) {
        yasqe.setValue(yasguiQuery);
    }
};

export interface YasguiModuleProps {
    endpoint: string;
}

export function YasguiModule({endpoint}: YasguiModuleProps) {
    const yasgui = useRef<Yasgui>();
    const getSearchParamsString = useSearchParamsString();

    React.useEffect(() => {
        const yasguiAnchor = document.getElementById('yasgui');
        Yasr.plugins.table.defaults.urlParams = getSearchParamsString();

        if (yasguiAnchor !== null) {
            if (yasgui.current) {
                yasgui.current.destroy();
            }
            const hash = location.hash;
            yasgui.current = new Yasgui(yasguiAnchor, {
                requestConfig: {endpoint: endpoint},
                copyEndpointOnNewTab: false,
                persistenceId: endpoint,
                yasqe: {
                    createShareableLink,
                    // @ts-expect-error FIXME why is typing broken here?
                    consumeShareLink,
                    requestConfig: {endpoint: endpoint},
                },
            });
            // FIXME Yasgui tries to reset the hash for some reason
            // So we save it and reasign here to prevent it from resetting the router
            location.hash = hash;
        }
    }, [endpoint]);

    return <div id="yasgui" />;
}

export function YasguiPage() {
    const navigate = useNavigateWithParams();

    const endpoint = useEndpoint();

    if (endpoint === null) {
        navigate('/', undefined, true);
        return null;
    }

    return <YasguiModule endpoint={endpoint} />;
}
