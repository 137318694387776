import React from 'react';
import {Box, Divider, IconButton, Stack, Typography} from '@mui/material';
import {ViewSelector} from '../ViewSelector';
import {useEndpoint} from '../../context/ConfigContext';
import {Link} from 'react-router-dom';
import ArrowLeftIcon from '@mui/icons-material/ChevronLeft';
import {GraphSelector} from '../GraphSelector';

export const DRAWER_WIDTH = 340;

export function DrawerContent({onClose}: {onClose: () => void}) {
    const endpoint = useEndpoint();
    return (
        <Box
            height={'100%'}
            display={'flex'}
            flexDirection={'column'}
            width={340}
        >
            <Stack direction={'row'}>
                <Box
                    padding={1}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                >
                    <IconButton size="large" color="inherit" onClick={onClose}>
                        <ArrowLeftIcon />
                    </IconButton>
                </Box>
                <Box padding={1} flex={1}>
                    <Typography variant="h5">SparqlExplorer</Typography>
                    {endpoint ? (
                        <Link to={endpoint} target="_blank">
                            <Typography variant="caption" color={'GrayText'}>
                                {endpoint}
                            </Typography>
                        </Link>
                    ) : null}
                </Box>
            </Stack>
            <Divider />
            <Box paddingTop={2}>
                <Stack spacing={1}>
                    <ViewSelector />
                    <Divider />
                    <GraphSelector />
                </Stack>
            </Box>
        </Box>
    );
}
