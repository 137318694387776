import React, {useEffect, useState} from 'react';
import {useParam} from '../hooks/useParams';
import {useEndpoint} from '../context/ConfigContext';
import {querySparql, sparqlResultToArray} from '@logilab/sparqlutils';
import {Loading, NoResults} from './UtilsComponents';
import {RDFNode, prefixedNameFromUri} from '../utils/utils';
import {
    Box,
    Card,
    Chip,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
} from '@mui/material';
import {Link} from 'react-router-dom';
import {useURILink} from '../hooks/useURILink';
import CheckIcon from '@mui/icons-material/Check';
import {getIconFromURI} from '../utils/getIconFromURI';
import {useNavigateWithParams} from '../hooks/useNavigateWithParams';

interface Result {
    node: RDFNode;
    label: string;
    types: string[];
}

export function SearchPage() {
    const searchInput = useParam('search');
    const [results, setResults] = useState<Result[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const endpoint = useEndpoint();

    const navigate = useNavigateWithParams();

    useEffect(() => {
        if (!endpoint || !searchInput) {
            navigate('/', undefined, true);
            return;
        }

        try {
            setLoading(true);
            querySparql(
                endpoint,
                `select distinct ?x ?z (GROUP_CONCAT(DISTINCT ?type, ", ") as ?types) where {
                 {?x ?y ?z. OPTIONAL { ?x a ?type.} }
                UNION{
                    GRAPH ?g
                    {?x ?y ?z. OPTIONAL { ?x a ?type.} }
                }
                FILTER(isLiteral(?z) && regex(str(?z), "${searchInput}","i"))
                } GROUP BY ?x ?z
                LIMIT 100`
            ).then((queryResults) => {
                const temp = sparqlResultToArray(queryResults).map((elem) => {
                    return {
                        node: elem['x'],
                        label: elem['z'].value,
                        types: elem['types'].value.split(', '),
                    };
                });
                setResults(temp);
                setLoading(false);
            });
        } catch (e) {
            console.error(e.message);
            setLoading(false);
        }
    }, [endpoint, searchInput]);

    if (!endpoint || !searchInput) {
        return null;
    }

    if (loading) {
        return <Loading />;
    }

    return <ResultsDisplay results={results} searchValue={searchInput} />;
}

function ResultsDisplay({
    results,
    searchValue,
}: {
    results: Result[];
    searchValue: string;
}) {
    const [allDifferentTypes, setAllDifferentTypes] = useState<string[]>([]);
    const [renderResults, setRenderResults] = useState<
        {result: Result; score: number}[]
    >([]);
    const [selectedType, setSelectedType] = useState<string | null>(null);
    React.useEffect(() => {
        const comparisonValue = searchValue.toLowerCase();
        const scoredResults: {result: Result; score: number}[] = results.map(
            (result) => {
                const resultValue = result.label.toLowerCase();
                if (resultValue === comparisonValue) {
                    return {result, score: 1};
                }
                return {
                    result,
                    score: 1 / (resultValue.length - comparisonValue.length),
                };
            }
        );
        setAllDifferentTypes(
            Array.from(new Set(results.map((r) => r.types).flat()))
        );
        scoredResults.sort((result1, result2) => result2.score - result1.score);
        setRenderResults(scoredResults);
    }, [results, searchValue]);

    if (results.length === 0) {
        return <NoResults />;
    }
    return (
        <Stack spacing={2} width={'100%'}>
            <Stack
                direction={'row'}
                spacing={1}
                display={'flex'}
                alignItems={'center'}
            >
                <Box>
                    <Typography>Filter:</Typography>
                </Box>
                {allDifferentTypes.map((type) => (
                    <Chip
                        icon={
                            selectedType !== null && selectedType === type ? (
                                <CheckIcon />
                            ) : undefined
                        }
                        onClick={() => {
                            selectedType === type
                                ? setSelectedType(null)
                                : setSelectedType(type);
                        }}
                        variant={
                            selectedType !== null && selectedType === type
                                ? 'filled'
                                : 'outlined'
                        }
                        label={prefixedNameFromUri(type)}
                    />
                ))}
            </Stack>
            <Card>
                <List>
                    {renderResults.map(({result: elem}) => (
                        <ResultListItem
                            selectedType={selectedType}
                            result={elem}
                        />
                    ))}
                </List>
            </Card>
        </Stack>
    );
}

function ResultListItem({
    selectedType,
    result,
}: {
    selectedType: string | null;
    result: Result;
}) {
    const uriLink = useURILink(result.node.value);
    if (selectedType !== null && result.types.indexOf(selectedType) < 0) {
        return null;
    }

    const Icon = getIconFromURI(result.types[0]);

    return (
        <Link
            to={uriLink}
            key={result.node.value + result.label}
            style={{
                textDecoration: 'none',
                color: 'inherit',
            }}
        >
            <ListItemButton divider>
                <ListItemIcon>
                    <Icon />
                </ListItemIcon>
                <ListItemText
                    primary={
                        result.label.length > 180
                            ? `${result.label.slice(0, 180)}...`
                            : result.label
                    }
                    secondary={result.types
                        .map((t) => prefixedNameFromUri(t))
                        .join(', ')}
                />
            </ListItemButton>
        </Link>
    );
}
