import {SparqlViewConfig} from '@logilab/sparqlexplorer-views';
import React, {PropsWithChildren, useContext} from 'react';

const SelectedViewContext = React.createContext<{
    selectedView?: SparqlViewConfig;
    setSelectedView: (view?: SparqlViewConfig) => void;
}>({selectedView: undefined, setSelectedView: () => undefined});

export function SelectedViewProvider({children}: PropsWithChildren) {
    const [selectedView, setSelectedView] = React.useState<
        SparqlViewConfig | undefined
    >();
    return (
        <SelectedViewContext.Provider
            value={{
                selectedView,
                setSelectedView,
            }}
        >
            {children}
        </SelectedViewContext.Provider>
    );
}

export function useSelectedView() {
    return useContext(SelectedViewContext);
}
