import React from 'react';
import {YasguiPage} from '../components/Yasgui';
import {Layout} from '../components/layout/Layout';
import {Container} from '@mui/material';

export function Yasgui() {
    return (
        <Layout>
            <Container>
                <YasguiPage />
            </Container>
        </Layout>
    );
}
