import React, {useEffect, useState} from 'react';
import {useNavigateWithParams} from '../../hooks/useNavigateWithParams';
import {
    AppBar,
    IconButton,
    InputBase,
    Toolbar,
    Typography,
    styled,
    alpha,
    Button,
    Box,
    Tooltip,
    Stack,
    AppBarProps,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import SearchIcon from '@mui/icons-material/Search';
import LinkIcon from '@mui/icons-material/Link';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TerminalIcon from '@mui/icons-material/Terminal';
import {useMatch, useParams} from 'react-router-dom';
import {useEndpoint} from '../../context/ConfigContext';
import {useParam} from '../../hooks/useParams';
import {DRAWER_WIDTH} from './DrawerContent';

interface CustomAppBarProps extends AppBarProps {
    open?: boolean;
}

const CustomAppBar = styled(AppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<CustomAppBarProps>(({theme, open}) => ({
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        marginLeft: `${DRAWER_WIDTH}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Search = styled('div')(({theme}) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.05),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.black, 0.1),
    },
    flex: 1,
    height: 40,
}));

export function NavBar({
    onBurgerPressed,
    drawerOpen,
}: {
    onBurgerPressed: () => void;
    drawerOpen: boolean;
}) {
    const searchInput = useParam('search');
    const params = useParams();
    const currentUri = params['*'];

    const [currentSearch, setCurrentSearch] = useState(
        currentUri ?? searchInput ?? ''
    );
    const navigate = useNavigateWithParams();
    const endpoint = useEndpoint();

    useEffect(() => {
        if (currentUri && currentUri !== currentSearch) {
            setCurrentSearch(currentUri);
        }
    }, [currentUri]);

    useEffect(() => {
        if (searchInput && searchInput !== currentSearch) {
            setCurrentSearch(searchInput);
        }
    }, [searchInput]);

    function startSearch() {
        if (!currentSearch) {
            return;
        }

        const encodedSearch = encodeURIComponent(currentSearch);
        if (
            currentSearch.startsWith('http://') ||
            currentSearch.startsWith('https://') ||
            currentSearch.startsWith('urn:')
        ) {
            navigate(`/browse/${encodedSearch}`);
        } else {
            navigate('/search', {
                search: encodedSearch,
            });
        }
    }

    // We show the home screen on all routes not matching those patterns
    // See App.tsx
    const onYasgui = useMatch('/yasgui');
    const onSearch = useMatch('/search');
    const onUri = useMatch('/browse/:uri');

    const showHomeButton =
        onYasgui !== null || onSearch !== null || onUri !== null;

    return (
        <>
            <CustomAppBar position="static" open={drawerOpen}>
                <Toolbar>
                    {!drawerOpen ? (
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            sx={{mr: 2}}
                            onClick={onBurgerPressed}
                        >
                            <MenuIcon />
                        </IconButton>
                    ) : null}
                    <Stack
                        direction={'row'}
                        spacing={1}
                        alignItems={'center'}
                        flexGrow={1}
                    >
                        {!drawerOpen ? (
                            <Typography
                                variant="h6"
                                noWrap
                                component="a"
                                sx={{
                                    display: {xs: 'none', sm: 'block'},
                                    cursor: 'pointer',
                                    textDecoration: 'none',
                                    color: 'inherit',
                                }}
                                onClick={() => navigate('/')}
                            >
                                SparqlExplorer
                            </Typography>
                        ) : null}
                        {showHomeButton ? (
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                sx={{mr: 2}}
                                onClick={() => navigate('/')}
                            >
                                <HomeIcon />
                            </IconButton>
                        ) : null}
                        {endpoint ? (
                            <Search>
                                <Stack
                                    paddingLeft={1}
                                    paddingRight={1}
                                    direction={'row'}
                                    spacing={1}
                                    alignItems={'center'}
                                    flexGrow={1}
                                    height={'100%'}
                                >
                                    <Box>
                                        {!currentUri ||
                                        currentUri !== currentSearch ? (
                                            <SearchIcon />
                                        ) : (
                                            <LinkIcon />
                                        )}
                                    </Box>
                                    <form
                                        style={{flex: 1, display: 'flex'}}
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            startSearch();
                                        }}
                                    >
                                        <InputBase
                                            sx={{
                                                flex: 1,
                                            }}
                                            name="search"
                                            placeholder="Search…"
                                            inputProps={{
                                                'aria-label': 'search',
                                            }}
                                            value={currentSearch}
                                            onChange={(event) =>
                                                setCurrentSearch(
                                                    event.target.value
                                                )
                                            }
                                        />
                                    </form>
                                    {currentUri &&
                                    currentUri === currentSearch ? (
                                        <Tooltip title="Open URI">
                                            <IconButton
                                                href={currentUri}
                                                target="_blank"
                                                color="inherit"
                                            >
                                                <OpenInNewIcon />
                                            </IconButton>
                                        </Tooltip>
                                    ) : null}
                                </Stack>
                            </Search>
                        ) : null}
                        {endpoint ? (
                            <Button
                                sx={{color: 'inherit'}}
                                onClick={() => navigate('/yasgui')}
                                startIcon={<TerminalIcon />}
                            >
                                Query
                            </Button>
                        ) : null}
                    </Stack>
                </Toolbar>
            </CustomAppBar>
        </>
    );
}
