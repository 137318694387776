import React from 'react';
import {Layout} from '../components/layout/Layout';
import {HomePage} from '../components/HomePage';
import {Container} from '@mui/material';

export function Home() {
    return (
        <Layout>
            <Container>
                <HomePage />
            </Container>
        </Layout>
    );
}
