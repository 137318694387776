import {useEffect, useState} from 'react';
import {ClassListProps, ListQueryResult} from '../components/ClassList';
import {sparqlResultToArray, querySparql} from '@logilab/sparqlutils';
import {getLabelSparqlClause} from '../utils/utils';

export function useClasses({endpoint}: ClassListProps) {
    const [classes, setClasses] = useState<ListQueryResult[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        async function fetchClasses() {
            try {
                setIsLoaded(false);
                setError(null);
                // Ignore OpenLinks classes as these are internal to virtuoso
                const queryResults = await querySparql(
                    endpoint,
                    `SELECT DISTINCT ?uri (COUNT(?x) as ?c) ?label WHERE {
                        ?x a ?uri
                        ${getLabelSparqlClause(undefined, true)}
                    } GROUP BY ?uri ?label ORDER BY DESC(?c)`
                );
                const temp = sparqlResultToArray(queryResults).map((elem) => {
                    return {
                        uri: elem['uri'].value,
                        nbInstances: elem['c'].value,
                        label: elem['label']?.value,
                    };
                });
                // Filtering within the request is too slow
                const filteredTemp = temp.filter(
                    (e) => !e.uri.startsWith('http://www.openlinksw.com')
                );
                setClasses(filteredTemp);
            } catch (e) {
                setError(e.message);
            } finally {
                setIsLoaded(true);
            }
        }
        fetchClasses();
    }, [endpoint]);

    return {classes, error, isLoaded};
}
