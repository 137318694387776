import React, {useEffect} from 'react';

import './ViewSelector.css';
import {useSelectedView} from '../context/SelectedViewContext';
import {useView} from '../context/ConfigContext';
import {useChangeView} from '../hooks/useNavigateWithParams';
import {useApplicableViews} from '../context/ApplicableViewsContext';
import {SPARQL_VIEWS, SparqlViewConfig} from '@logilab/sparqlexplorer-views';
import {
    Box,
    FormControlLabel,
    List,
    ListItemButton,
    Radio,
    Switch,
    Typography,
} from '@mui/material';
import {useParams} from 'react-router-dom';
import {DEFAUT_VIEW_NAME, isDefaultView} from '../utils/utils';

export function ViewSelector() {
    const view = useView();
    const params = useParams();
    const currentUri = params['*'];

    const {selectedView} = useSelectedView();
    const {applicableViews, setApplicableViews} = useApplicableViews();

    const isAutomatic = () => view === null;
    const changeView = useChangeView();

    const allViews: Array<SparqlViewConfig> = [
        {
            name: DEFAUT_VIEW_NAME,
            component: () => undefined,
            isApplicable: () => Promise.resolve(true),
        },
        ...SPARQL_VIEWS,
    ];

    useEffect(() => {
        if (!currentUri && applicableViews) {
            setApplicableViews(undefined);
        }
    }, [currentUri, applicableViews]);

    return (
        <div>
            <Box paddingLeft={1} paddingRight={1}>
                <Typography variant="h6">Available views</Typography>
                <FormControlLabel
                    control={
                        <Switch
                            checked={isAutomatic()}
                            onChange={() => {
                                changeView(
                                    isAutomatic() ? DEFAUT_VIEW_NAME : null
                                );
                            }}
                        />
                    }
                    label="Automatic"
                />
            </Box>
            <List>
                {allViews.map((item) => {
                    const selected =
                        (selectedView === undefined &&
                            isDefaultView(item.name)) ||
                        (selectedView !== undefined &&
                            selectedView.name === item.name)
                            ? true
                            : false;
                    return (
                        <ListItemButton
                            disabled={
                                !isDefaultView(item.name) &&
                                !applicableViews?.find(
                                    (v) => v.name === item.name
                                )
                            }
                            key={item.name}
                            onClick={() => changeView(item.name)}
                            selected={selected}
                        >
                            <Radio checked={selected} size="small" />
                            {item.name}
                        </ListItemButton>
                    );
                })}
            </List>
        </div>
    );
}
